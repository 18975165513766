<template>
  <component :is="tag" @click.prevent="clickText">
    <transition name="fade">
      <span v-if="showMessage"
            class="position-absolute top-0 left-0 alert"
            :class="messageClass"
      ><small>{{ message }}</small></span>
    </transition>
    <slot></slot>
    <cs-button v-if="showButton"
               @handle-click="copyText"
               :icon-left="copied?copiedIcon:icon"
               :type="copied?copiedButtonType:buttonType"
               :size="buttonSize"
    >
      {{ copied ? copiedButtonText : buttonText }}
    </cs-button>
  </component>
</template>

<script>
export default {
  name: 'CopiableText',
  props: {
    tag: {
      type: String,
      default: 'span',
      required: false
    },
    showButton: {
      type: Boolean,
      default: true
    },
    buttonText: {
      type: String,
      default: ''
    },
    copiedButtonText: {
      type: String,
      default: ''
    },
    buttonSize: {
      type: String,
      default: 'sm'
    },
    buttonType: {
      type: String,
      default: 'info'
    },
    copiedButtonType: {
      type: String,
      default: 'secondary'
    },
    clickableText: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: 'clipboard'
    },
    copiedIcon: {
      type: String,
      default: 'clipboard-check'
    }
  },
  data () {
    return {
      copied: false,
      message: '',
      success: true
    }
  },
  computed: {
    buttonIcon () {
      return this.copiedIcon ? this.copiedIcon : this.icon
    },
    messageClass () {
      if (this.success) {
        return ['alert-success']
      } else {
        return ['alert-danger']
      }
    },
    showMessage () {
      return this.message !== ''
    },
    copyContent () {
      if (this.$slots.default.length > 0) {
        const content = this.$slots.default[0]
        return content.text
      }
      return ''
    }
  },
  methods: {
    clickText () {
      if (this.clickableText) {
        this.copyText()
      }
    },
    copyText () {
      const theText = this.copyContent
      console.log('copied', theText)
      const vm = this
      navigator.clipboard.writeText(theText).then(() => {
        /* clipboard successfully set */
        this.message = `'${theText}' copied to clipboard`
        this.success = true
        this.copied = true
      }, () => {
        /* clipboard write failed */
        this.message = `'${theText}' not copied to clipboard`
        this.success = false
      })
      setTimeout(() => {
        vm.message = ''
      }, 2000)
    }
  }
}
</script>

<style scoped>

</style>
